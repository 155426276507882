import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 615.000000 615.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,615.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 3075 l0 -3075 3075 0 3075 0 0 3075 0 3075 -3075 0 -3075 0 0
-3075z m2574 744 c20 -16 146 -251 146 -273 0 -2 37 -79 82 -172 47 -98 83
-187 86 -211 4 -38 2 -43 -22 -55 -20 -9 -29 -9 -34 -1 -4 6 -21 15 -37 19
-17 4 -42 16 -57 26 -26 18 -28 18 -28 2 0 -26 -13 -6 -37 56 -34 88 -35 93
-39 138 -2 24 -7 48 -11 55 -4 7 -8 26 -9 42 -1 51 -72 111 -116 100 -46 -12
-70 -48 -194 -291 -70 -137 -130 -251 -133 -254 -3 -3 -14 -21 -23 -40 -35
-71 -85 -152 -99 -161 -22 -14 -49 -11 -49 6 0 8 -6 14 -12 12 -26 -5 -30 -11
254 559 156 315 225 444 241 452 34 17 62 14 91 -9z m1112 -44 c212 -36 343
-143 417 -340 55 -148 59 -283 13 -431 -30 -93 -67 -155 -139 -231 -55 -59
-108 -90 -197 -118 -83 -26 -473 -34 -518 -11 -66 34 -80 125 -24 163 24 16
55 19 257 23 209 5 234 7 277 27 62 29 121 92 161 172 30 62 32 71 32 181 0
108 -2 119 -31 180 -39 82 -141 189 -204 215 -65 26 -260 34 -282 11 -9 -9
-23 -16 -32 -16 -21 0 -27 -27 -24 -111 4 -112 -25 -159 -98 -159 -34 0 -81
51 -90 95 -3 18 -4 97 -2 175 l3 142 34 30 34 30 166 -7 c91 -3 203 -13 247
-20z m681 -57 c11 -13 42 -79 68 -148 26 -69 66 -168 87 -220 69 -167 96 -233
122 -300 13 -35 29 -66 35 -68 12 -4 17 6 106 227 33 79 68 152 79 162 47 44
106 10 106 -61 0 -21 -3 -30 -8 -22 -9 13 -53 -35 -79 -85 -16 -32 -37 -104
-34 -120 1 -7 -2 -13 -8 -13 -9 0 -37 -94 -35 -117 1 -7 -2 -13 -7 -13 -5 0
-9 -8 -9 -18 0 -10 -6 -26 -13 -37 -7 -11 -12 -39 -12 -62 1 -23 -1 -40 -5
-38 -4 2 -17 -10 -29 -26 -20 -27 -28 -30 -62 -27 -26 2 -39 -1 -39 -9 0 -6
-5 -15 -12 -19 -7 -5 -8 -3 -4 5 5 7 2 18 -7 24 -8 7 -26 41 -40 77 -14 36
-46 115 -72 175 -26 61 -66 160 -90 220 -23 61 -73 186 -110 279 -46 116 -65
176 -61 192 16 62 93 86 133 42z m703 -28 c27 -27 25 -44 -5 -60 -34 -18 -89
-2 -93 27 -2 15 5 26 25 37 37 20 49 20 73 -4z m-1363 -106 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-648 -770 c13 -9 29 -32 37 -50 17 -40 7
-72 -32 -103 -58 -45 -153 -1 -156 72 -3 75 89 124 151 81z m1931 -356 c0 -13
-5 -30 -10 -38 -9 -13 -12 -13 -21 3 -16 27 -38 20 -44 -16 -9 -46 -42 -48
-53 -4 -11 41 -39 48 -48 12 -9 -36 -28 -26 -32 18 l-3 36 93 4 c51 1 99 4
106 5 6 1 12 -8 12 -20z m195 -28 c5 -38 3 -53 -8 -65 -13 -13 -17 -10 -32 28
l-17 42 -20 -39 c-21 -41 -39 -36 -31 9 3 12 0 32 -6 43 -14 27 -6 32 55 32
l52 0 7 -50z m-754 16 c47 -20 63 -117 29 -173 -25 -42 -44 -51 -130 -61 -58
-7 -73 -6 -77 5 -3 8 -1 19 6 26 14 14 15 177 1 177 -5 0 -10 9 -10 19 0 17 8
20 48 23 47 3 103 -4 133 -16z m258 -13 c34 -129 39 -157 35 -165 -3 -4 4 -8
15 -8 12 0 21 -5 21 -11 0 -6 -7 -8 -16 -5 -9 3 -20 6 -26 6 -6 0 -8 7 -5 15
4 8 2 17 -2 20 -5 3 -12 -3 -15 -12 -6 -15 -9 -14 -24 7 -14 20 -24 24 -51 22
-19 -2 -41 -11 -48 -20 -14 -19 -17 -33 -5 -31 16 3 32 -1 32 -8 0 -5 -11 -8
-25 -8 -14 0 -25 4 -25 9 0 5 -8 1 -19 -8 -16 -15 -19 -15 -24 -1 -4 9 -1 15
7 15 20 0 60 155 46 180 -17 31 -4 40 60 40 l59 0 10 -37z m-738 -37 c8 -33
20 -61 26 -63 6 -2 16 25 23 62 l12 65 69 0 c68 0 85 -10 59 -36 -16 -16 -20
-175 -5 -180 7 -3 11 -14 8 -25 -4 -15 -14 -19 -49 -19 -45 0 -64 14 -45 33 6
6 11 42 11 80 0 69 -12 83 -24 27 -18 -95 -37 -138 -61 -142 -36 -7 -43 2 -69
88 l-25 79 0 -67 c-1 -38 3 -68 10 -70 5 -2 7 -11 4 -21 -10 -24 -83 -24 -92
0 -4 9 -1 19 5 21 16 5 16 174 1 189 -7 7 -9 19 -5 28 5 13 18 15 68 13 l63
-3 16 -59z m-542 -64 l35 -113 34 3 c35 3 35 4 59 88 20 68 22 89 13 104 -18
29 0 37 76 34 l69 -3 30 -107 c18 -64 35 -108 42 -108 7 0 13 -9 13 -20 0 -17
-7 -20 -50 -22 -27 -1 -58 -2 -67 -2 -11 -1 -19 8 -21 22 -2 15 2 22 13 22 10
0 15 7 13 18 -4 24 -70 29 -88 7 -12 -15 -12 -18 3 -26 9 -6 17 -19 17 -29 0
-17 -7 -20 -55 -20 -41 0 -56 4 -64 18 -10 16 -10 16 -11 0 0 -19 -4 -20 -90
-20 -48 0 -55 2 -58 21 -3 16 2 21 18 21 16 0 20 4 16 16 -3 9 -6 18 -6 20 0
2 -18 4 -40 4 -29 0 -42 -5 -50 -20 -9 -16 -8 -20 5 -20 21 0 19 -37 -2 -42
-10 -2 -63 -3 -118 -2 -83 1 -101 4 -103 18 -2 9 1 16 7 16 14 0 15 119 1 140
-10 17 -7 23 -37 -78 -11 -35 -22 -63 -26 -63 -4 0 -20 -4 -37 -9 -19 -5 -30
-5 -30 1 0 6 -4 8 -9 5 -5 -4 -17 25 -27 64 -10 38 -21 70 -25 70 -9 0 -19
-54 -19 -101 0 -28 4 -39 15 -39 8 0 15 -7 15 -15 0 -18 -12 -19 -28 -3 -9 9
-12 9 -12 0 0 -7 -13 -12 -30 -12 -20 0 -30 5 -30 15 0 8 6 15 13 15 14 0 11
183 -4 188 -5 2 -9 15 -9 28 0 23 2 24 74 24 l75 0 11 -57 c15 -79 17 -83 33
-77 7 2 19 34 26 69 l13 65 74 0 c67 0 74 -2 74 -19 0 -11 -4 -23 -10 -26 -5
-3 -10 -47 -10 -96 l0 -90 31 3 c30 3 33 7 55 82 16 53 22 91 18 117 l-6 37
73 -1 73 -2 35 -113z m-672 92 c2 -7 -3 -18 -11 -26 -9 -10 -16 -47 -21 -106
-6 -82 -4 -92 10 -92 9 0 15 -6 12 -12 -5 -18 -117 -18 -117 -1 0 7 8 13 18
13 15 0 16 9 12 90 -3 51 -10 94 -17 102 -21 20 -15 36 15 40 54 6 95 3 99 -8z
m-434 -1 c4 -3 1 -16 -8 -29 -8 -13 -15 -53 -17 -100 -3 -77 -4 -79 -28 -76
-33 4 -40 17 -40 87 0 38 -6 69 -16 83 -8 12 -12 27 -9 32 7 11 108 14 118 3z
m-253 -26 c0 -12 9 -54 19 -92 11 -38 18 -71 16 -74 -2 -2 -11 4 -20 13 -9 9
-25 21 -35 26 -10 5 -26 28 -36 50 -19 43 -39 52 -49 20 -9 -27 -35 -25 -35 3
0 12 -7 31 -15 41 -8 11 -15 23 -15 28 0 4 38 8 85 8 82 0 85 -1 85 -23z
m3100 -67 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25
-4 25 -10z m-2590 -80 c0 -18 -7 -20 -55 -20 -41 0 -55 4 -55 14 0 17 19 23
73 25 31 1 37 -2 37 -19z m2480 16 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0
16 -2 16 -4z m67 -2 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m60 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-3162 -14 c-3
-5 -22 -10 -41 -10 -19 0 -34 5 -34 10 0 6 18 10 41 10 24 0 38 -4 34 -10z
m2665 0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
-10z m187 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m83 -4 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-2530
-23 c0 -31 -27 -50 -80 -55 -38 -3 -51 -2 -46 7 4 6 23 11 42 11 32 0 34 2 28
25 -6 23 -3 25 25 25 19 0 31 -5 31 -13z m-390 -57 c0 -5 -7 -10 -16 -10 -8 0
-12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m123 3 c-7 -2 -19 -2 -25 0 -7 3
-2 5 12 5 14 0 19 -2 13 -5z m64 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-374 -11 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m2630 -79 c21 -12 32 -13 35 -5 2 6 12 11 23 11 15 0 19 -7 19 -39 0 -23 -6
-44 -16 -52 -13 -10 -19 -8 -40 14 -30 33 -47 34 -74 5 -29 -31 -28 -101 2
-122 12 -9 31 -13 45 -10 14 4 23 2 23 -5 0 -6 -3 -11 -7 -12 -5 0 -18 -2 -30
-5 -12 -2 -29 3 -37 11 -9 10 -24 13 -39 10 -18 -5 -26 -1 -35 20 -30 66 -2
155 57 179 20 8 37 15 39 15 2 1 17 -6 35 -15z m-293 -68 c20 -33 41 -61 48
-64 18 -6 15 63 -3 82 -32 31 -19 46 41 46 l56 0 -16 -25 c-9 -14 -19 -57 -22
-98 l-7 -74 -42 -1 c-37 -2 -45 2 -74 38 -17 22 -36 40 -41 40 -6 0 -10 -22
-10 -49 0 -41 3 -50 21 -54 12 -3 18 -10 15 -15 -7 -12 -46 -4 -46 10 0 5 -7
2 -16 -6 -8 -9 -20 -16 -25 -16 -12 0 -12 17 1 25 13 8 3 165 -11 180 -17 17
-9 34 19 38 63 10 74 4 112 -57z m-160 39 c0 -7 -6 -18 -14 -22 -19 -11 -27
-183 -8 -183 6 0 12 -7 12 -15 0 -17 -59 -20 -95 -5 -18 7 -19 8 -2 15 14 6
17 20 17 85 0 57 -4 83 -15 94 -32 32 -19 46 45 46 46 0 60 -3 60 -15z m-410
-14 c0 -10 -7 -24 -15 -31 -11 -9 -15 -34 -15 -101 0 -87 -10 -113 -33 -90 -6
6 -19 11 -29 11 -19 0 -61 37 -89 80 -22 32 -27 26 -33 -38 -4 -46 -2 -52 15
-52 10 0 19 -4 19 -10 0 -14 -33 -13 -48 2 -9 9 -12 8 -12 -4 0 -18 -37 -31
-47 -16 -3 6 0 16 8 22 17 13 14 173 -3 193 -21 26 -1 43 52 43 l50 0 41 -65
c22 -36 45 -65 50 -65 16 0 11 87 -6 94 -25 9 -17 34 13 39 65 10 82 8 82 -12z
m211 3 c39 -20 63 -75 54 -123 -14 -79 -58 -109 -159 -113 -37 -1 -66 -7 -67
-13 0 -5 -4 -1 -8 10 -5 13 -3 24 6 30 11 9 14 29 10 89 -3 43 -10 83 -16 89
-6 6 -11 19 -11 29 0 16 10 18 80 18 54 0 91 -5 111 -16z m-523 -40 c-4 -34
-42 -46 -62 -19 -8 11 -22 16 -41 13 -26 -3 -30 -8 -33 -35 -3 -32 -2 -33 37
-33 23 0 41 -3 41 -7 -1 -19 -18 -32 -49 -35 -31 -3 -34 -6 -36 -40 l-2 -38
44 0 c35 0 43 3 43 19 0 12 9 20 25 23 31 6 33 3 28 -43 l-5 -35 -66 9 c-37 4
-73 14 -81 20 -12 10 -14 9 -9 -3 4 -11 -2 -15 -28 -15 -40 0 -53 11 -24 20
20 6 21 11 14 93 -5 62 -12 90 -23 99 -23 17 -36 2 -87 -99 -24 -48 -47 -88
-50 -88 -3 0 -4 6 -1 14 3 7 1 18 -4 23 -6 6 -2 30 12 68 17 45 19 62 10 81
-6 14 -7 26 -1 29 5 3 80 8 167 12 87 3 160 7 162 9 11 10 22 -16 19 -42z
m-422 11 c3 -8 -1 -22 -10 -30 -16 -16 -14 -26 17 -92 14 -32 2 -69 -23 -69
-20 0 -20 1 -4 13 10 7 15 16 11 19 -4 4 -14 1 -24 -7 -15 -12 -16 -12 -10 4
4 11 2 17 -8 17 -9 0 -12 6 -9 15 4 8 1 15 -5 15 -6 0 -20 18 -31 40 -12 23
-27 43 -35 47 -27 10 -16 32 18 36 75 9 108 7 113 -8z m1694 -60 c0 -19 -27
-55 -41 -55 -9 0 -20 -6 -22 -12 -3 -7 -6 -3 -6 8 -1 12 -8 27 -16 34 -26 22
-16 30 35 30 28 0 50 -2 50 -5z m-1650 -137 c0 -13 -23 -5 -28 10 -2 7 2 10
12 6 9 -3 16 -11 16 -16z m477 -44 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z"/>
<path d="M3268 3353 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4341 2411 c-6 -11 -11 -51 -11 -90 0 -69 1 -71 24 -71 14 0 32 10
40 22 22 31 21 111 -1 130 -32 27 -42 29 -52 9z"/>
<path d="M4610 2385 c-6 -16 -13 -36 -15 -42 -3 -9 6 -13 26 -13 27 0 29 2 23
28 -13 52 -22 60 -34 27z"/>
<path d="M3604 2350 c-6 -22 -9 -42 -6 -45 8 -8 40 -6 46 3 6 10 -15 82 -24
82 -3 0 -11 -18 -16 -40z"/>
<path d="M3292 2351 c-18 -50 -15 -61 13 -61 29 0 32 17 11 65 l-12 30 -12
-34z"/>
<path d="M2678 2183 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3717 2013 c-4 -3 -7 -44 -7 -90 0 -80 1 -83 23 -83 38 0 61 34 61
90 0 34 -6 56 -19 70 -18 20 -45 26 -58 13z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
